<template>
  <div class="staff">
    <div class="staff_content" >
      <div>
        <input type="text" v-model="staffSelf.name" placeholder="请输入姓名">
        <span class="staff_input_warn"
              :class="this.staffSelf.name.length!==0?'success':'error'">
          {{ this.staffSelf.name.length===0?'*':'√'}}
        </span>
      </div>

      <div>
        <input type="tel" v-model="staffSelf.tel" placeholder="请输入手机号码">
        <span class="staff_input_warn"
              :class="valTelFormat(staffSelf.tel)?'success':'error'">
          {{ this.staffSelf.tel.length===0?'*':(valTelFormat(staffSelf.tel)?'√':'请检查格式')}}</span>
          <p class="tel_info">* 联系方式用于接收紧急或重要活动信息提醒，请准确填写。
              <span style="color: red">现场奖品以手签为准，随机发放，如您现场签到后未领取，管理员将通过邮件或此电话与您联系。</span></p>
      </div>
<!--      <div class="staff_checkbox">-->
<!--          <input type="checkbox" v-model="staffSelf.isEntourage"><span>是否增加随行人员</span>-->
<!--      </div>-->
<!--      <div class="staff_checkbox">-->
<!--        <input type="checkbox" v-model="staffSelf.isGift" @click="checkGift"><span>请不要给我邮寄奖品</span>-->
<!--      </div>-->
    </div>
<!--    <div class="staff_gift_recv_info" v-show="!staffSelf.isGift">-->
<!--        &lt;!&ndash;-->
<!--      <p class="sgri_title">奖品领取信息</p>-->
<!--      <p class="sgri_info">* 健步走参与奖奖品将按照现场签到顺序发放</p>-->
<!--      &ndash;&gt;-->
<!--      <div class="register_addr_input_box">-->
<!--          <span class="city_wrapper_title">收件地址：</span>-->
<!--          <area-select  type='text' :level='2' v-model="staffSelf.gift.city" :data="pcaa"></area-select>-->
<!--      </div>-->
<!--      <div class="sgri_content">-->
<!--        <div>-->
<!--          <input type="text" v-model="staffSelf.gift.addr" placeholder="请输入详细地址">-->
<!--          <span class="staff_input_warn"-->
<!--                :class="this.staffSelf.gift.addr.length!==0?'success':'error'">-->
<!--            {{ this.staffSelf.gift.addr.length===0?'*':'√'}}-->
<!--          </span>-->
<!--        </div>-->
<!--        <div>-->
<!--          <input type="text" v-model="staffSelf.gift.name" placeholder="收件人姓名">-->
<!--          <span class="staff_input_warn"-->
<!--                :class="this.staffSelf.gift.name.length!==0?'success':'error'">-->
<!--            {{ this.staffSelf.gift.name.length===0?'*':'√'}}-->
<!--          </span>-->
<!--        </div>-->
<!--        <div>-->
<!--          <input type="tel" v-model="staffSelf.gift.tel" placeholder="收件人电话">-->
<!--          <span class="staff_input_warn"-->
<!--            :class="valTelFormat(staffSelf.gift.tel)?'success':'error'">-->
<!--          {{ this.staffSelf.gift.tel.length===0?'*':(valTelFormat(staffSelf.gift.tel)?'√':'请检查格式')}}</span>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="staff_button">
      <button class="staff_button_back" @click="goBack">返回</button>
      <button class="staff_button_next"
              :class=" isActive() ? 'active' : ''"
              :disabled="isDisabled()"
              @click="nextPage">下一步</button>
    </div>

  </div>
</template>

<script>
import {mapActions,mapState} from "vuex";
import {valIdCard, valTel} from "./valid";
import register from "../../store/register";
import pcaa from "area-data/pcaa";

export default {
  name: "staff",
  data(){
    return{
      // staffSelf:{
      //   name:'',
      //   idCard:'',
      //   gift:{addr:'',name:'',tel:''},
      // //  isEntourage:'',
      // },

      isShow:false,
      pcaa: pcaa,
    }
  },

  mounted(){
    // this.staffSelf = this.$store.getters["register/getStaff"]
    this.pageTitle('报名信息')
  },
  computed:{
    ...mapState({
      staffSelf:state => state.register.staff
    })
  },
  methods:{
    ...mapActions({
      staffSelfGift:'register/staffSelfGift',
      pageTitle:'register/pageTitle'
    }),
    checkGift(){
      console.log(this.staffSelf.isGift);
      if (this.staffSelf.isGift){
        console.log('要礼品');
        this.staffSelf.gift.tel = ''
        this.staffSelf.gift.name = ''
        this.staffSelf.gift.addr = ''
      }else {
        console.log('不要礼品',this.staffSelf.isGift);
        this.staffSelf.gift.tel = '18000000000'
        this.staffSelf.gift.name = '-'
        this.staffSelf.gift.addr = '-'
      }
    },
    //输入格式判断
    valIdCardFormat(idCard){
      return valIdCard(idCard);
    },
    valTelFormat(tel){
      return valTel(tel);
    },
    //按钮显示\disabled
    isActive(){
      return this.staffSelf.name.length!==0
          &&valTel(this.staffSelf.tel)
          // &&this.staffSelf.gift.addr.length!==0
          // &&this.staffSelf.gift.city.length!==0
          // &&this.staffSelf.gift.name.length!==0
          // &&valTel(this.staffSelf.gift.tel)

    },
    isDisabled(){
      return this.staffSelf.name.length===0
          ||!(valTel(this.staffSelf.tel))
          //||!(valIdCard(this.staffSelf.idCard))
          // ||this.staffSelf.gift.addr.length===0
          // ||this.staffSelf.gift.city.length===0
          // ||!(this.staffSelf.gift.name.length!==0)
          // ||!(valTel(this.staffSelf.gift.tel))
    },


    goBack(){
      this.$router.push({name:'register.place'})
    },
    nextPage(){
        console.log('g');
        this.staffSelfGift(this.staffSelf)
      // if (this.staffSelf.isEntourage){
      //   this.$router.replace({name:'register.entourage'})
      // }else{
        this.$router.replace({name:'register.info'})
      // }
    },



  },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
.error
  color $error
.success
  color green
.staff
  padding 0 38px 61px
  background-color white
  .staff_content
    border-bottom 2px solid #CCCCCC
    >div
      position relative
      padding-top  20px
      >input
        inputSet()
        width 674px
      input::placeholder
        inputPlaceholderSet()
      .staff_input_warn
        //color $error
        position absolute
        right 40px
        line-height 86px
    .staff_checkbox
      height 86px
      margin-left 34px
      display flex
      flex-direction row
      align-items center
      padding 20px 0
      input
        width 50px
        height 50px
        outline none
        margin 0
      span
        height 50px
        font-size 36px
        font-family "source han sans cn"
        color #333333
        line-height 51px
        padding-left 20px

    .tel_info
      padding-top 20px
      padding-right 20px
      font-size 28px
      font-weight lighter
      text-align left
      margin  0
      padding-bottom 20px
  .staff_gift_recv_info
    .sgri_title
      titleSet()
      margin 60px 0 5px
    .sgri_info
      //padding-bottom 20px
      padding-top 20px
      padding-right 20px
      font-size 20px
      font-weight lighter
      text-align right
      margin  0
    .city_wrapper_title
      display block
      font-size 36px
      padding-bottom 20px
    .sgri_content
      >div
        position relative
        padding-top  20px
        >input
          inputSet()
          width 674px
        input::placeholder
          inputPlaceholderSet()
        .staff_input_warn
          //color $error
          position absolute
          right 40px
          line-height 86px
  .staff_button
    margin-top 80px
    display flex
    flex-direction row
    justify-content space-between
    button
      buttonSet()
      width 329px
      color $gray
      background $bcGray
    .active
      buttonSet()
      width 329px
      color #FFF



::v-deep .area-select-wrap{
  width 100%
  display flex
  flex-direction column
  justify-content center
}
::v-deep .area-select-wrap .area-select{
  margin 20px 0 0
  buttonSet()
  width 100%
  color $gray
  background $bcGray
}
::v-deep .area-select .area-selected-trigger {
  display: block;
  font-size 30px
  margin: 0;
  line-height 86px;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  padding: 0 0 0 44px
}
::v-deep .area-select .area-select-icon {
  border 10px solid transparent
  border-top-color: #a1a4ad;
  position: absolute;
  top: 50%;
  right: 40px;
}
::v-deep .area-selectable-list .area-select-option{
  padding  0 0 0 44px
  font-size 30px
  height 68px
  line-height 68px
}
::v-deep .area-selectable-list .area-select-option.selected {
  background-color: #e4e8f1;
  color $theme
  font-weight: 700
}
</style>
